import React from 'react';
import Modal from 'react-modal';

const ProjectModal = ({ project, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={project.title}
      className="modal"
      overlayClassName="overlay"
    >
      <button className="close-button" onClick={onRequestClose}>Close</button>
      <div className="modal-content">
        <h1>{project.title}</h1>
        <img src={project.image} alt={project.title} />
        <p>{project.summary}</p>
        <p>Detailed description of {project.title}...</p>
      </div>
    </Modal>
  );
};

export default ProjectModal;
