import React, { useState } from 'react';
import ProjectCard from './ProjectCard';
import ProjectModal from './ProjectModal';

  const Projects = () => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const projectList = [
      { id: 1, title: 'Toronto House History', summary: 'A flask web app to help you find who lived in your Toronto house. It uses the OpenAI API to narrate what previous owners did, and makes a summary of your house', link: 'toronto.aguadulce.tech', description: 'The University of Toronto digitized the Historical City directories but it takes a few hours to go through the guides to learn who lived in your house (I did it). This project helps you get this in < 1 min and also writes an AI generated summary and a 1st person narration of the people who lived in your house.', image: '/images/project1.jpg' },
      { id: 2, title: 'Rc Internet control car', summary: 'Converted an off the shelf RC car to be controlled over the internet using an RPIZero.', link: 'https://car.aguadulce.tech', description: 'See project description link: ' , image: '/images/project2.jpg' },
      { id: 3, title: 'Project 3', summary: 'Summary of project 3', image: '/images/project3.jpg' },
      { id: 4, title: 'Real Estate Toronto App', summary: 'A real estate app focused on Toronto with a modern design. Developed with the first generation of microsoft form recognizer model in 2019, will be redeveloped with GPT', link: 'https://realtor.aguadulce.tech' , image: '/images/project4.jpg' },
      { id: 5, title: 'CN Tower Telescope', summary: 'A real time view of the CN tower from my telescope. ', link: 'https://65acc0f78bd305a90ec9144695e83a64.serveo.net/control' , image: '/images/project5.jpg' },
    ];
  
    const handleProjectClick = (project) => {
      setSelectedProject(project);
      setIsModalOpen(true);
    };

  const handleCloseModal = () => {
    setSelectedProject(null);
    setIsModalOpen(false);
  };

  return (
    <div className="projects-container">
      <div className="projects">
        {projectList.map((project) => (
          <ProjectCard key={project.id} project={project} onClick={() => handleProjectClick(project)} />
        ))}
      </div>
      {selectedProject && (
        <ProjectModal
          project={selectedProject}
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Projects;
