import React from 'react';

const About = () => {
  return (
    <div className="about">
      <h1>About Me</h1>
      <p>I am a Product Manager at Microsoft currently working on our B2B software marketplace. 
        I am passionate about technology,  product development and many other things. Here are some links to my work:</p>
      <a href="https://github.com/manknewl" className="about-link">GitHub</a>
      <a href="https://linkedin.com/in/mcerrutti" className="about-link">LinkedIn</a>
    </div>
  );
};

export default About;
